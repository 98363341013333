<template>
  <div class="waiting-list">
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primary"
      class="my-10"
    ></v-progress-linear>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="waitingList"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @pageCount="pageCount"
      class="elevation-0"
      fixed-header
      height="60vh"
      hide-default-header
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              role="columnheader"
              scope="col"
              aria-label="No"
              class="text-start font-weight-bold"
              :style="'width:' + header.width + '; min-width:' + header.width + ';'"
            >
              <span>{{ header.text }}</span>
            </th>
          </tr>
        </thead>
      </template>
    </v-data-table>
    <div class="d-flex justify-space-between mt-2 px-2">
      <v-select
        outlined
        hide-details
        dense
        :value="itemsPerPage"
        :items="[20, 50, 100, 200]"
        @change="itemsPerPage = parseInt($event, 10)"
        class="select-paging"
      ></v-select>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        elevation="0"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { student_profile } from '@/api/student-profile.js'
export default {
  props: {
    userID: Number,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 100,
      headers: [
        {
          text: 'ID',
          value: 'studentID',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Student Code',
          value: 'studentCode',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Student Name',
          value: 'fullName',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Date',
          value: 'tranDate',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Status',
          value: 'waitingListStatus',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Note',
          value: 'note',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Location',
          value: 'location',
          sortable: false,
          // width: '20%',
        },
      ],
      waitingList: [],
    }
  },
  created() {
    this.getWaitingList()
  },
  methods: {
    async getWaitingList() {
      this.isLoading = true
      await student_profile.getWaitingList(this.userID).then(
        res =>
          (this.waitingList = res.map(el => {
            return {
              ...el,
              tranDate : el.tranDate ? dayjs(el.tranDate).format('DD/MM/YYYY') : '-'
            }
          }))
      )
      this.isLoading = false
    },
    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.waiting-list {
  padding: 20px 24px;
  .select-paging {
    height: 32px;
    max-width: 100px;
  }
}
</style>
