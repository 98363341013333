import { render, staticRenderFns } from "./PlacementTest.vue?vue&type=template&id=fff74ef6&scoped=true&"
import script from "./PlacementTest.vue?vue&type=script&lang=js&"
export * from "./PlacementTest.vue?vue&type=script&lang=js&"
import style0 from "./PlacementTest.vue?vue&type=style&index=0&id=fff74ef6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fff74ef6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VDataTable,VProgressLinear})
