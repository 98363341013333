<template>
  <v-row no-gutters>
    <v-col md="12">
      <div class="pa-10 pt-5">
        <h1 v-if="currentRole === 1" color="primary">Bài kiểm tra</h1>
        <div class="d-flex justify-space-between mt-3">
          <div class="d-flex">
            <v-autocomplete
              label="Chọn lớp"
              v-model="classID"
              append-icon="keyboard_arrow_down"
              :items="classList"
              item-text="classCode"
              item-value="classID"
              outlined
              dense
              hide-details
              class="mr-2 mb-3"
            ></v-autocomplete>
          </div>
        </div>
        <div class="d-flex align-center mb-3">
          <h3>
            <!-- {{ classInfo }} -->
            {{ classInfo.classCode }}
          </h3>
          <p class="ml-2">
            ({{ classInfo.status }}) from {{ _dayjs(classInfo.startDate).format('DD/MM/YYYY') }} to
            {{ _dayjs(classInfo.endDate).format('DD/MM/YYYY') }}
          </p>
          <h3 class="ml-5">Shift:</h3>
          <p class="ml-2">{{ getTime() }}</p>
          <h3 class="ml-5">Weekday:</h3>
          <p class="ml-2">{{ getWeekday() }}</p>
        </div>

        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
          class="my-10"
        ></v-progress-linear>
        <v-data-table
          v-if="!isLoading"
          :headers="logHeaders"
          :items="currentRole === 1 ? justStudentViewList : studentTestList"
          :page.sync="page"
          :items-per-page="1000000000000000"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          class="elevation-0 exam-table"
          id="student-exam-table"
          fixed-header
          height="30vh"
          style="border: 1px solid #fff"
        >
          <template v-slot:header="{}">
            <thead style="border-radius: 20px; text-align: center" class="exam-header">
              <tr>
                <th
                  v-for="(header, index) in logHeaders"
                  :key="index"
                  class="stu-exam-master-type-header"
                  style="
                    background: #0b2585;
                    color: #fff;
                    text-align: center;
                    border: 1px solid #fafaff;
                  "
                  :colspan="header.child.length === 0 ? 1 : header.child.length"
                  :rowspan="header.child.length === 0 ? 2 : 1"
                  v-html="header.text"
                ></th>
              </tr>
              <tr>
                <template v-for="(header, index) in logHeaders">
                  <th
                    :key="index + headerChild"
                    v-for="headerChild in header.child"
                    style="text-align: center; border: 1px solid #fafaff"
                    v-html="headerChild"
                  ></th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:body="{ items }">
            <tbody
              v-if="studentTestList.length === 0 && justStudentViewList.length === 0"
              style="text-align: center"
            >
              <div
                class="pt-3"
                style="position: absolute; left: 50%; transform: translate(-50%, 0)"
              >
                No data available
              </div>
            </tbody>
            <tbody v-else :class="'exam-body'">
              <tr v-for="item in items" :key="item.student_UserID">
                <td :class="'w-150'" v-for="test in item.tests" :key="test.testID">
                  <div
                    class="d-flex justify-space-between align-center"
                    v-if="getLastTest(item.tests, test)"
                  >
                    <div v-if="test.mark && showIcon(item.tests, test) === 'Public'">
                      <p>
                        {{ test.mark }}
                      </p>
                    </div>
                    <div v-else>
                      <p>-</p>
                    </div>
                    <div>
                      <img
                        v-if="currentRole === 1"
                        @click="getTestInfo(item.tests, test), $refs.viewDetailTest.open()"
                        :src="
                          checkIcon(item.tests, test)
                            ? showIcon(item.tests, test) === 'Public'
                              ? iconPublic
                              : iconNone
                            : iconNone
                        "
                        srcset=""
                      />
                      <img
                        v-else
                        @click="getTestInfo(item.tests, test), $refs.viewDetailTest.open()"
                        :src="
                          checkIcon(item.tests, test)
                            ? showIcon(item.tests, test) === 'Public'
                              ? iconPublic
                              : iconDraft
                            : iconNone
                        "
                        srcset=""
                      />
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center" v-else>
                    <div v-if="test.mark && showIcon(item.tests, test) === 'Public'">
                      <p>
                        {{ test.mark }}
                      </p>
                    </div>
                    <div v-else>
                      <p>-</p>
                    </div>
                  </div>
                </td>
                <td v-if="currentRole !== 1" class="d-flex justify-center align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        v-if="!item.note"
                        v-bind="attrs"
                        v-on="on"
                        :style="{ width: '18px' }"
                        @click="openEditNote(item)"
                        src="../../../assets/images/done-note.svg"
                        alt=""
                      />
                      <img
                        v-else
                        v-bind="attrs"
                        v-on="on"
                        :style="{ width: '18px' }"
                        @click="openEditNote(item)"
                        src="../../../assets/images/notes.svg"
                        alt=""
                      />
                    </template>
                    <span v-if="!item.note">Create note</span>
                    <span v-else>View note</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
      <div v-show="overlay" :class="'h'">
        <div class="card-i">
          <v-card :style="{ boxShadow: 'none' }" light>
            <v-card-title class="text-h5" :style="{ fontWeight: 700 }">
              Student Exam Note
            </v-card-title>
            <v-divider></v-divider>
            <v-textarea
              :v-model="noteText"
              name="input-7-1"
              label="Add Student Exam Result note"
              :disabled="!isEdit"
              :value="noteText"
              @change="noteOnChange"
              outlined
              dense
              height="30vh"
              class="mt-3 ml-5 mr-5"
            ></v-textarea>
            <v-divider></v-divider>

            <v-card-actions v-if="currentRole === 4 || currentRole === 5">
              <v-spacer></v-spacer>
              <div :class="'mb-4'" class="btnNote">
                <v-btn elevation="2" large @click="overlay = false">Cancel</v-btn>
                <v-btn elevation="2" large color="primary" @click="saveNote" v-if="isEdit === true"
                  >Save</v-btn
                >
                <v-btn elevation="2" large color="primary" @click="btn_Edit" v-else>Edit</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </div>
      <TestDialog
        maxWidth="50vw"
        maxHeight="80vh"
        :testInfo="testInfo"
        :videoUrl="videoUrl"
        :teacherComment="teacherComment"
        :examStatus="examStatus"
        ref="viewDetailTest"
        @callData="getStudentIDWithScroll"
      />
    </v-col>
  </v-row>
</template>

<script>
import { class_managerment } from '@/api/class-management.js'
import { student_information } from '@/api/student-information'
import { all_test } from '@/api/all-test.js'
import dayjs from 'dayjs'
import iconPublic from '../../../assets/images/exam-result-public.svg'
import iconDraft from '../../../assets/images/exam-result-draft.svg'
import iconNone from '../../../assets/images/exam-result-none.svg'
import { getObjectPermission } from '../../../common/index'
import TestDialog from '@/components/ClassManagement/TestOverall/TestDialog.vue'
import SortByOrder from '@/mixins/sortClassByOrder.js'

export default {
  components: {
    TestDialog,
  },
    mixins: [SortByOrder],
  props: {
    userID: String,
  },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      numberRules: [v => !!v || 'This filed is required', v => !isNaN(v) || 'Must be number'],
      textRules: [v => !!v || 'This filed is required'],
      permision: getObjectPermission(439),
      iconPublic: iconPublic,
      iconDraft: iconDraft,
      iconNone: iconNone,
      centers: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      stu_userID: JSON.parse(localStorage.getItem('currentUser')).userInfo.userID,
      locationID: JSON.parse(localStorage.getItem('currentCenter')),
      listNote: [],
      isLoading: false,
      isEdit: false,
      overlay: false,
      noteText: '',
      recordID: 0,
      videoUrl: '',
      teacherComment: '',
      examStatus: '',
      studentID: 0,
      studentSearchID: 0,
      testInfo: [],
      classList: [],
      masterType: [],
      actualMasterType: [],
      studentTestList: [],
      justStudentViewList: [],
      testDate: [],
      page: 1,
      pageCount: 0,
      classID: 0,
      classInfo: {},
      classInfos: [],
    }
  },
  computed: {
    logHeaders() {
      const headerTitle = []
      this.masterType.forEach(el => {
        headerTitle.push({
          text: el.testTypeCategory,
          child: el.tests.map(
            el => el.testType + '</br>' + dayjs(el.testDate).format('DD/MM/YYYY')
          ),
        })
      })
      if (this.currentRole !== 1) {
        headerTitle.push({
          text: 'Note',
          child: [],
        })
      }

      return headerTitle
    },
  },
  created() {
    this.searchClass()
    this.getMasterTypeInClass()
    this.getStudentID()
  },

  methods: {
    //FOR REDIRECTING NOTIFICATION
    openExamNoti() {
      // const masterTypeID = 829
      const payload = this.$store.getters.getNotiPayload

      if (Object.keys(payload).length > 0) {
        //clean up noti payload in store and set flag to disable re-render
        const testTypeID = payload.testtypeid
        const studentID = payload.studentID
        this.classID = payload.classID

        const getStudentTest = this.studentTestList.find(el => el.studentID === studentID).tests

        const firstTest = getStudentTest.find(el => el.testTypeID === testTypeID)
        this.getTestInfo(getStudentTest, firstTest)
        this.scrollToTheLeft()
        this.$refs.viewDetailTest.open()

        this.$store.commit('setNotiPayload', {})
      }
    },
    scrollToTheLeft() {
      /**
       * waiting DOM to update then call the method of scrolling to left
       */
      this.$nextTick(() => {
        const tableWrapper = document.querySelector('#student-exam-table > div')
        const allHeader = document.querySelectorAll('.stu-exam-master-type-header')
        const targetIndex = Array.from(allHeader).findIndex(
          el => el.innerText === this.testInfo[0].masterType
        )
        const targetElement = Array.from(allHeader)[targetIndex - 1]
        if (!targetElement) return
        const scrollLeft = targetElement.offsetLeft - tableWrapper.offsetLeft - 100
        tableWrapper.scrollBy({
          left: scrollLeft,
          behavior: 'smooth',
        })
      })
    },
    getWeekday() {
      const weekdayPattern = /[A-Za-z]+(?=[/\s])/g
      // Extract the weekdays using the matchAll() method
      const matches = this.classInfo?.shift?.match(weekdayPattern)
      // Retrieve the weekdays from the matches
      return [...new Set(matches)].join(', ')
    },
    getTime() {
      // Regular expression pattern to match the time ranges
      const timePattern = /\d{2}:\d{2}-\d{2}:\d{2}/g
      // Extract the time ranges using the matchAll() method
      const matches = this.classInfo?.shift?.match(timePattern)
      // Retrieve the time ranges from the matches
      return [...new Set(matches)].join(' / ')
    },
    getLastTest(list, item) {
      const sameRootTest = list.filter(el => el.masterTypeID === item.masterTypeID)
      return sameRootTest[sameRootTest.length - 1].testID === item.testID
    },
    getElementVal(arr, str) {
      return arr.map(el => el[str]).filter(y => y)
    },
    getTestInfo(list, item) {
      const sameRootTest = list.filter(el => el.masterTypeID === item.masterTypeID)
      this.testInfo = sameRootTest.map(el => el)
      this.videoUrl =
        this.getElementVal(this.testInfo, 'videoUrl').length > 0
          ? this.getElementVal(this.testInfo, 'videoUrl')[0]
          : ''
      this.teacherComment =
        this.getElementVal(this.testInfo, 'teacherComment').length > 0
          ? this.getElementVal(this.testInfo, 'teacherComment')[0]
          : ''
      this.examStatus =
        this.getElementVal(this.testInfo, 'testResultStatus').length > 0
          ? this.getElementVal(this.testInfo, 'testResultStatus')[0]
          : ''
    },
    checkIcon(list, item) {
      const sameRootTest = list.filter(el => el.masterTypeID === item.masterTypeID)
      return (
        this.getElementVal(sameRootTest, 'videoUrl').length > 0 ||
        this.getElementVal(sameRootTest, 'mark').length > 0 ||
        this.getElementVal(sameRootTest, 'teacherComment').length > 0
      )
    },
    showIcon(list, item) {
      const sameRootTest = list.filter(el => el.masterTypeID === item.masterTypeID)
      return this.getElementVal(sameRootTest, 'testResultStatus')[0]
    },
    openEditNote(item) {
      if (item.note == null || item.note == '' || item.note === undefined) this.isEdit = true
      else this.isEdit = false
      this.overlay = true
      this.studentID = item.studentID
      this.noteText = item.note
      this.recordID = item.noteID
    },
    noteOnChange(value) {
      this.noteText = value
    },
    async saveNote() {
      const body = this.recordID
        ? {
            recordID: this.recordID,
            classID: this.classID,
            studentID: this.studentID,
            teacherComment: this.noteText,
          }
        : {
            classID: this.classID,
            studentID: this.studentID,
            teacherComment: this.noteText,
          }

      await all_test.updateExamNote(body)
      this.getMasterTypeInClass()
      this.getStudentID()
      this.overlay = false
    },

    btn_Edit() {
      this.isEdit = true
    },
    async searchClass() {
      await class_managerment.getClassRe(this.userID ? this.userID : this.stu_userID).then(res => {
        if (res) {
          const targetResult = this.getLatestInprogress(res)
          this.classInfos = targetResult
          this.classInfo = targetResult[0]
          this.classID = targetResult[0].classID
          this.classList = targetResult
        }
      })
    },
    async getStudentID() {
      this.isLoading = true
      await student_information
        .getStudentInfor(this.userID ? this.userID : this.stu_userID)
        .then(async res => {
          await all_test.GetTestByStudent(this.classID, res.studentID).then(res => {
            if (res.length > 0) {
              // Create an empty array to store merged information
              const mergedInfo = []

              // Iterate through the array and merge information based on testTypeCategory
              res.forEach(element => {
                const {
                  studentName,
                  studentCode,
                  studentID,
                  classCode,
                  classID,
                  qcName,
                  qcid,
                  ecname,
                  ecUserId,
                  dob,
                  actualStartDate,
                  student_UserID,
                } = element

                // Check if the testTypeCategory already exists in the mergedInfo array
                const existingElement = mergedInfo.find(el => el.student_UserID === student_UserID)

                // If the testTypeCategory already exists, push the current element to its "tests" array
                if (existingElement) {
                  existingElement.tests.push(element)
                } else {
                  // If the testTypeCategory doesn't exist, create a new object and add it to the mergedInfo array
                  const newElement = {
                    ecname,
                    ecUserId,
                    dob,
                    actualStartDate,
                    studentName,
                    studentCode,
                    studentID,
                    classCode,
                    classID,
                    qcName,
                    qcid,
                    student_UserID,
                    tests: [element],
                  }
                  mergedInfo.push(newElement)
                }
              })
              this.testDate.forEach((date, index) => {
                mergedInfo.forEach(el => {
                  if (el.tests && el.tests[index]) {
                    el.tests[index].testDate = date
                  }
                })
              })
              const testTypeIDs = this.masterType.flatMap(item =>
                item.tests.map(test => test.testTypeID)
              )
              mergedInfo[0].tests.sort(
                (a, b) => testTypeIDs.indexOf(a.testTypeID) - testTypeIDs.indexOf(b.testTypeID)
              )
              /**
               * !check if contain test with no test name and master type
               */
              const nullTestArr = mergedInfo[0].tests.filter(item => item.masterType === null)
              if (nullTestArr.length > 0) {
                mergedInfo[0].tests.sort((a, b) => {
                  if (a.testTypeID === 0 && b.testTypeID !== 0) {
                    return 1 // Move a to the end
                  }
                  if (a.testTypeID !== 0 && b.testTypeID === 0) {
                    return -1 // Keep a before b
                  }
                  return 0 // Maintain the original order
                })
                this.masterType.push({
                  classCode: this.masterType[0].classCode,
                  classID: this.masterType[0].classID,
                  testTypeCategory: 'NULL',
                  testTypeCategoryID: 0,
                  tests: [],
                })
                const afterConfig = nullTestArr.map(el => {
                  return {
                    classCode: el.classCode,
                    classID: el.classID,
                    ordinalNo: el.ordinalNo ? el.ordinalNo : 'No ordinalNo',
                    testDate: el.testDate ? el.testDate : 'No Date',
                    testID: el.testID,
                    testType: 'Null',
                    testTypeCategory: 'Null',
                    testTypeCategoryID: 0,
                    testTypeID: 0,
                  }
                })
                this.masterType[this.masterType.length - 1].tests = [...afterConfig].flat()
              }

              this.studentTestList = mergedInfo

              if (this.currentRole === 1) {
                mergedInfo[0].tests = mergedInfo[0].tests.map(el => {
                  return {
                    ...el,
                    teacherComment: el.testResultStatus !== 'Public' ? '' : el.teacherComment,
                    videoUrl: el.testResultStatus !== 'Public' ? '' : el.videoUrl,
                    // mark: el.testResultStatus !== 'Public' ? '' : el.mark,
                  }
                })
                this.justStudentViewList = mergedInfo
              }
              // console.log(this.studentTestList,this.justStudentViewList)
            } else {
              this.studentTestList = []
              this.justStudentViewList = []
              this.isLoading = false
            }
          })
          await all_test.getNote(this.classID).then(res => {
            if (this.currentRole === 4 || this.currentRole === 5) {
              this.studentTestList.forEach(obj1 => {
                const obj2 = res.find(obj => obj.studentID === obj1.studentID)
                if (obj2) {
                  obj1.note = obj2.teacherComment
                  obj1.noteID = obj2.recordID
                  this.isLoading = false
                }
              })
            } else {
              this.justStudentViewList.forEach(obj1 => {
                const obj2 = res.find(obj => obj.studentID === obj1.studentID)
                if (obj2) {
                  obj1.note = obj2.teacherComment
                  obj1.noteID = obj2.recordID
                  this.isLoading = false
                }
              })
            }
            this.openExamNoti()
          })
        })
    },
    async getStudentIDWithScroll() {
      this.isLoading = true
      await student_information
        .getStudentInfor(this.userID ? this.userID : this.stu_userID)
        .then(async res => {
          await all_test.GetTestByStudent(this.classID, res.studentID).then(res => {
            if (res.length > 0) {
              // Create an empty array to store merged information
              const mergedInfo = []

              // Iterate through the array and merge information based on testTypeCategory
              res.forEach(element => {
                const {
                  studentName,
                  studentCode,
                  studentID,
                  classCode,
                  classID,
                  qcName,
                  qcid,
                  ecname,
                  ecUserId,
                  dob,
                  actualStartDate,
                  student_UserID,
                } = element

                // Check if the testTypeCategory already exists in the mergedInfo array
                const existingElement = mergedInfo.find(el => el.student_UserID === student_UserID)

                // If the testTypeCategory already exists, push the current element to its "tests" array
                if (existingElement) {
                  existingElement.tests.push(element)
                } else {
                  // If the testTypeCategory doesn't exist, create a new object and add it to the mergedInfo array
                  const newElement = {
                    ecname,
                    ecUserId,
                    dob,
                    actualStartDate,
                    studentName,
                    studentCode,
                    studentID,
                    classCode,
                    classID,
                    qcName,
                    qcid,
                    student_UserID,
                    tests: [element],
                  }
                  mergedInfo.push(newElement)
                }
              })
              this.testDate.forEach((date, index) => {
                mergedInfo.forEach(el => {
                  if (el.tests && el.tests[index]) {
                    el.tests[index].testDate = date
                  }
                })
              })
              const testTypeIDs = this.masterType.flatMap(item =>
                item.tests.map(test => test.testTypeID)
              )
              mergedInfo[0].tests.sort(
                (a, b) => testTypeIDs.indexOf(a.testTypeID) - testTypeIDs.indexOf(b.testTypeID)
              )
              /**
               * !check if contain test with no test name and master type
               */
              const nullTestArr = mergedInfo[0].tests.filter(item => item.masterType === null)
              if (nullTestArr.length > 0) {
                mergedInfo[0].tests.sort((a, b) => {
                  if (a.testTypeID === 0 && b.testTypeID !== 0) {
                    return 1 // Move a to the end
                  }
                  if (a.testTypeID !== 0 && b.testTypeID === 0) {
                    return -1 // Keep a before b
                  }
                  return 0 // Maintain the original order
                })
                this.masterType.push({
                  classCode: this.masterType[0].classCode,
                  classID: this.masterType[0].classID,
                  testTypeCategory: 'NULL',
                  testTypeCategoryID: 0,
                  tests: [],
                })
                const afterConfig = nullTestArr.map(el => {
                  return {
                    classCode: el.classCode,
                    classID: el.classID,
                    ordinalNo: el.ordinalNo ? el.ordinalNo : 'No ordinalNo',
                    testDate: el.testDate ? el.testDate : 'No Date',
                    testID: el.testID,
                    testType: 'Null',
                    testTypeCategory: 'Null',
                    testTypeCategoryID: 0,
                    testTypeID: 0,
                  }
                })
                this.masterType[this.masterType.length - 1].tests = [...afterConfig].flat()
              }

              this.studentTestList = mergedInfo

              if (this.currentRole === 1) {
                mergedInfo[0].tests = mergedInfo[0].tests.map(el => {
                  return {
                    ...el,
                    teacherComment: el.testResultStatus !== 'Public' ? '' : el.teacherComment,
                    videoUrl: el.testResultStatus !== 'Public' ? '' : el.videoUrl,
                    // mark: el.testResultStatus !== 'Public' ? '' : el.mark,
                  }
                })
                this.justStudentViewList = mergedInfo
              }
              // console.log(this.studentTestList,this.justStudentViewList)
            } else {
              this.studentTestList = []
              this.justStudentViewList = []
              this.isLoading = false
            }
          })
          await all_test.getNote(this.classID).then(res => {
            if (this.currentRole === 4 || this.currentRole === 5) {
              this.studentTestList.forEach(obj1 => {
                const obj2 = res.find(obj => obj.studentID === obj1.studentID)
                if (obj2) {
                  obj1.note = obj2.teacherComment
                  obj1.noteID = obj2.recordID
                  this.isLoading = false
                }
              })
            } else {
              this.justStudentViewList.forEach(obj1 => {
                const obj2 = res.find(obj => obj.studentID === obj1.studentID)
                if (obj2) {
                  obj1.note = obj2.teacherComment
                  obj1.noteID = obj2.recordID
                  this.isLoading = false
                }
              })
            }
            this.scrollToTheLeft()
          })
        })
    },
    async getMasterTypeInClass() {
      await all_test.getMasterType(this.classID).then(res => {
        if (res.length > 0) {
          // Create an empty array to store merged information
          const mergedInfo = []
          res.forEach(el => {
            this.testDate.push(el.testDate)
          })
          // Iterate through the array and merge information based on testTypeCategory
          res.forEach(element => {
            const { testTypeCategory, classCode, classID, testTypeCategoryID } = element

            // Check if the testTypeCategory already exists in the mergedInfo array
            const existingElement = mergedInfo.find(
              el => el.testTypeCategoryID === testTypeCategoryID
            )

            // If the testTypeCategory already exists, push the current element to its "tests" array
            if (existingElement) {
              existingElement.tests.push(element)
            } else {
              // If the testTypeCategory doesn't exist, create a new object and add it to the mergedInfo array
              const newElement = {
                testTypeCategoryID,
                testTypeCategory,
                classCode,
                classID,
                tests: [element],
              }
              mergedInfo.push(newElement)
            }
          })
          mergedInfo.forEach(element => {
            element.tests.sort((a, b) => a.testID - b.testID)
          })
          this.masterType = mergedInfo
        } else this.masterType = []
      })
    },
    _dayjs: dayjs,
  },
  watch: {
    classID() {
      this.testDate = []
      this.getMasterTypeInClass()
      this.getStudentID()
      this.classInfo = this.classInfos.filter(el => el.classID === this.classID)[0]
    },
  },
}
</script>
<style lang="scss" scoped>
#recording-management {
  .link {
    cursor: pointer;

    color: #427df2 !important;
  }
  .w-150 {
    width: 100px;
  }
  .link:hover {
    text-decoration: underline;
    font-weight: 700;
  }
}

.btnNote {
  float: right;
  margin-top: 20px;
}

.btnNote button {
  margin-left: 20px;
  min-width: 150px !important;
}
.card-i {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  left: 0;
}
.exam-table {
  table-layout: fixed;
  border-top: 1px solid #dae3e8 !important;
}
.exam-header th {
  color: #fff !important;
  background: #0c2e68 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  text-align: center;
}

.exam-body tr:nth-child(even) td:nth-child(3) {
  background: #eaf1ff !important;
}
.exam-body tr:nth-child(odd) td:nth-child(3) {
  background: #fff !important;
}
.exam-body tr:nth-child(even) td:nth-child(2) {
  background: #eaf1ff !important;
}
.exam-body tr:nth-child(odd) td:nth-child(2) {
  background: #fff !important;
}
.exam-body tr:nth-child(even) td:nth-child(1) {
  background: #eaf1ff !important;
}
.exam-body tr:nth-child(odd) td:nth-child(1) {
  background: #fff !important;
}
.exam-body tr:nth-child(even) {
  background: #eaf1ff !important;
}
.exam-body tr:nth-child(odd) {
  background: #fff !important;
}
</style>
