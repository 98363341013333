<template>
  <div class="placement-test">
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primary"
      class="my-10"
    ></v-progress-linear>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="placementTestList"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @pageCount="pageCount"
      class="elevation-0"
      fixed-header
      height="60vh"
      hide-default-header
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              role="columnheader"
              scope="col"
              aria-label="No"
              class="text-start font-weight-bold"
              :style="'width:' + header.width + '; min-width:' + header.width + ';'"
            >
              <span>{{ header.text }}</span>
            </th>
          </tr>
        </thead>
      </template>
    </v-data-table>
    
  </div>
</template>

<script>
import { student_profile } from '@/api/student-profile.js'
import dayjs from 'dayjs'
export default {
  props: {
    userID: Number,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 100000,
      headers: [
        {
          text: 'Placement Test ID',
          value: 'placementTestID',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Placement Test Status',
          value: 'placementTestStatus',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Test Date',
          value: 'testDate',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'StudentID',
          value: 'studentID',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Student Code',
          value: 'studentCode',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Full Name',
          value: 'fullName',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Student Type',
          value: 'studentType',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Programme',
          value: 'programme',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Interviewer',
          value: 'interviewer',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Counselor Name',
          value: 'counselorName',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Final Score',
          value: 'finalScore',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Teacher comment',
          value: 'teacherComment',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Achieved Level',
          value: 'archivedLevel',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Remarks',
          value: 'remarks',
          sortable: false,
          // width: '20%',
        }
      ],
      placementTestList: [],
    }
  },
  created() {
    this.getPlacementTest()
  },
  methods: {
    async getPlacementTest() {
      this.isLoading = true
      this.placementTestList = await student_profile.getPTest(this.userID)
      this.isLoading = false
    },
    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.placement-test {
  padding: 20px 24px;
  .select-paging {
    height: 32px;
    max-width: 100px;
  }
}
</style>
