<template>
  <div class="transaction-history">
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primary"
      class="my-10"
    ></v-progress-linear>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="transactionList"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
      class="elevation-0"
      fixed-header
      height="60vh"
      hide-default-header
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              role="columnheader"
              scope="col"
              aria-label="No"
              class="text-start font-weight-bold"
              :style="'width:' + header.width + '; min-width:' + header.width + ';'"
            >
              <span>{{ header.text }}</span>
            </th>
          </tr>
        </thead>
        <tr class="fixed-row-filter">
          <th v-for="header in props.headers" :key="header.text">
            <div v-if="filters.hasOwnProperty(header.value)">
              <Autocomplete
                v-if="filters.hasOwnProperty(header.value)"
                @setFilter="setFilter"
                :keyFilter="header.value"
                :styleBorder="'border-bottom'"
                :selectedInit="filters[header.value]"
                :listItem="groupColumnValueList(header.value)"
              />
            </div>
          </th>
        </tr>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
            :class="item.tranType.includes('Credit') ? 'bg-yellow' : 'bg-blue'"
          >
            <td>{{ item.location }}</td>
            <td>{{ _dayjs(item.tranDate).format('DD/MM/YYYY') }}</td>
            <td>{{ item.tranType }}</td>
            <td>{{ item.studentAccountType }}</td>
            <td>{{ numberWithCommas(item.beginAmount) }}</td>
            <td>{{ numberWithCommas(item.creditDebitAmount) }}</td>
            <td>{{ numberWithCommas(item.endAmount) }}</td>
            <td>{{ item.note }}</td>
            <td>{{ item.crtdUser }}</td>
            <td>{{ _dayjs(item.crtdDate).format('DD/MM/YYYY') }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div class="py-5">
      <b>Chú thích:</b>
      <ul>
        <li>Lịch sử giao dịch được hiển thị từ giao dịch mới nhất đến cũ nhất</li>
        <li>Nợ (Debit) là các khoản ghi tăng vào số dư tài khoản của HS tại CEC</li>
        <li>Có (Credit) là các khoản ghi giám vào số dư tài khoản của HS tại CEC</li>
      </ul>
    </div>
    <div class="d-flex justify-space-between mt-2 px-2">
      <v-select
        outlined
        hide-details
        dense
        :value="itemsPerPage"
        :items="[20, 50, 100, 200]"
        @change="itemsPerPage = parseInt($event, 10)"
        class="select-paging"
      ></v-select>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        elevation="0"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import { student_profile } from '@/api/student-profile.js'
import dayjs from 'dayjs'

export default {
  props: {
    userID: Number,
  },
  components: {
    Autocomplete,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 100,
      headers: [
        {
          text: 'Cơ sở',
          value: 'location',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Ngày',
          value: 'tranDate',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Nợ (Debit) / Có (Credit)',
          value: 'tranType',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Nội dung',
          value: 'studentAccountType',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Số dư trước giao dịch',
          value: 'beginAmount',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Nợ/Có',
          value: 'creditDebitAmount',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Số dư sau giao dịch',
          value: 'endAmount',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Ghi chú',
          value: 'note',
          sortable: false,
          width: '30%',
        },
        {
          text: 'Cập nhật bởi',
          value: 'crtdUser',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Ngày cập nhật',
          value: 'crtdDate',
          sortable: false,
          // width: '20%',
        },
      ],
      transactionListFiltered: [],
      filters: {
        location: [],
        tranDate: [],
        tranType: [],
        studentAccountType: [],
        beginAmount: [],
        creditDebitAmount: [],
        endAmount: [],
        note: [],
        crtdUser: [],
        crtdDate: [],
      },
    }
  },
  created(){
    this.getTransactionHistory()
  },
  computed: {
    transactionList() {
      return this.transactionListFiltered.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },
  methods: {
    async getTransactionHistory(){
      this.isLoading = true
      this.transactionListFiltered = await student_profile.getStudentAccount(this.userID)
      this.isLoading = false
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },
    groupColumnValueList(val) {
      return this.transactionListFiltered.map(d => d[val])
    },
    numberWithCommas(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.transaction-history {
  padding: 20px 24px;
  .select-paging {
    height: 32px;
    max-width: 100px;
  }
}
.bg-yellow {
  background-color: #FFEDC2;
}
.bg-blue {
  background-color: #d1eeff;
}
</style>
