var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-5 px-6"},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.courseList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":"","height":"60vh","hide-default-header":""},on:{"update:page":function($event){_vm.page=$event},"pageCount":_vm.pageCount},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"text-start font-weight-bold",style:('width:' + header.width + '; min-width:' + header.width + ';'),attrs:{"role":"columnheader","scope":"col","aria-label":"No"}},[_c('span',[_vm._v(_vm._s(header.text))])])}),0)]),_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"keyFilter":header.value,"styleBorder":'border-bottom',"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.itemsPerPage * (_vm.page - 1) + index + 1))])]}},{key:"item.classCode",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"link text-center",on:{"click":function($event){return _vm.viewClassDetail(item.classID)}}},[_vm._v(_vm._s(item.classCode))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._dayjs(item.actualStartDate).format('DD/MM/YYYY')))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.actualEndDate.includes('1900') ? '-' : _vm._dayjs(item.actualEndDate).format('DD/MM/YYYY'))+" ")])]}},{key:"item.classEndDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._dayjs(item.classEndDate).format('DD/MM/YYYY')))])]}},{key:"item.lastUpdateDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._dayjs(item.lUpdDate).format('DD/MM/YYYY')))])]}},{key:"item.payStatus",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.paymentStatus))])]}},{key:"item.studentType",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.studentType ? item.studentType : '-'))])]}},{key:"item.lastUpdateUser",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.lUpdUser ? item.lUpdUser : '-'))])]}},{key:"item.precedingClass",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.preClassCode ? item.preClassCode : '-'))])]}},{key:"item.remark",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.notes ? item.notes : '-'))])]}},{key:"item.enrollmentStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.formatEnrollmentStatus(item.enrollmentStatus)},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.enrollmentStatus))])])]}},{key:"item.outstanding",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.outStanding))])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between mt-2 px-2"},[_c('v-select',{staticClass:"select-paging",attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}}),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }