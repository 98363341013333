var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"telesale-info"},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.telesaleList,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":"","height":"60vh","hide-default-header":""},on:{"pageCount":_vm.pageCount},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"text-start font-weight-bold",style:('width:' + header.width + '; min-width:' + header.width + ';'),attrs:{"role":"columnheader","scope":"col","aria-label":"No"}},[_c('span',[_vm._v(_vm._s(header.text))])])}),0)])]}},{key:"item.tranDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._dayjs(item.tranDate).format('DD/MM/YYYY')))])]}},{key:"item.contractType",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.contractType ? item.contractType : '-'))])]}},{key:"item.counselorNote",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.counselorNote ? item.counselorNote : '-'))])]}},{key:"item.telesalesStatus",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.telesalesStatus ? item.telesalesStatus : '-'))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.category ? item.category : '-'))])]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }