<template>
  <div class="pt-5 px-6">
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primary"
      class="my-10"
    ></v-progress-linear>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="courseList"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @pageCount="pageCount"
      class="elevation-0"
      fixed-header
      height="60vh"
      hide-default-header
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              role="columnheader"
              scope="col"
              aria-label="No"
              class="text-start font-weight-bold"
              :style="'width:' + header.width + '; min-width:' + header.width + ';'"
            >
              <span>{{ header.text }}</span>
            </th>
          </tr>
        </thead>
        <tr class="fixed-row-filter">
          <th v-for="header in props.headers" :key="header.text">
            <div v-if="filters.hasOwnProperty(header.value)">
              <Autocomplete
                v-if="filters.hasOwnProperty(header.value)"
                @setFilter="setFilter"
                :keyFilter="header.value"
                :styleBorder="'border-bottom'"
                :selectedInit="filters[header.value]"
                :listItem="groupColumnValueList(header.value)"
              />
            </div>
          </th>
        </tr>
      </template>
      <template v-slot:[`item.no`]="{ index }">
        <p class="text-center">{{ itemsPerPage * (page - 1) + index + 1 }}</p>
      </template>
      <template v-slot:[`item.classCode`]="{ item }">
        <p class="link text-center" @click="viewClassDetail(item.classID)">{{ item.classCode }}</p>
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        <p class="text-center">{{ _dayjs(item.actualStartDate).format('DD/MM/YYYY') }}</p>
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        <p class="text-center">
          {{
            item.actualEndDate.includes('1900')
              ? '-'
              : _dayjs(item.actualEndDate).format('DD/MM/YYYY')
          }}
        </p>
      </template>
      <template v-slot:[`item.classEndDate`]="{ item }">
        <p class="text-center">{{ _dayjs(item.classEndDate).format('DD/MM/YYYY') }}</p>
      </template>
      <template v-slot:[`item.lastUpdateDate`]="{ item }">
        <p class="text-center">{{ _dayjs(item.lUpdDate).format('DD/MM/YYYY') }}</p>
      </template>
      <template v-slot:[`item.payStatus`]="{ item }">
        <p class="text-center">{{ item.paymentStatus }}</p>
      </template>
      <template v-slot:[`item.studentType`]="{ item }">
        <p class="text-center">{{ item.studentType ? item.studentType : '-' }}</p>
      </template>
      <template v-slot:[`item.lastUpdateUser`]="{ item }">
        <p class="text-center">{{ item.lUpdUser ? item.lUpdUser : '-' }}</p>
      </template>
      <template v-slot:[`item.precedingClass`]="{ item }">
        <p class="text-center">{{ item.preClassCode ? item.preClassCode : '-' }}</p>
      </template>
      <template v-slot:[`item.remark`]="{ item }">
        <p class="text-center">{{ item.notes ? item.notes : '-' }}</p>
      </template>
      <template v-slot:[`item.enrollmentStatus`]="{ item }">
        <div :class="formatEnrollmentStatus(item.enrollmentStatus)">
          <p class="text-center">{{ item.enrollmentStatus }}</p>
        </div>
      </template>
      <template v-slot:[`item.outstanding`]="{ item }">
        <p class="text-center">{{ item.outStanding }}</p>
      </template>
    </v-data-table>
    <div class="d-flex justify-space-between mt-2 px-2">
      <v-select
        outlined
        hide-details
        dense
        :value="itemsPerPage"
        :items="[20, 50, 100, 200]"
        @change="itemsPerPage = parseInt($event, 10)"
        class="select-paging"
      ></v-select>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        elevation="0"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import dayjs from 'dayjs'
import { student_profile } from '@/api/student-profile.js'

export default {
  props: {
    userID: Number,
  },
  components: {
    Autocomplete,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 100,
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
        },
        {
          text: 'Class Code',
          value: 'classCode',
          sortable: false,
          width: '10%',
        },
        {
          text: 'Act.Start Date',
          value: 'startDate',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Act.End Date',
          value: 'endDate',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Cls.End Date',
          value: 'classEndDate',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Enrollment status',
          value: 'enrollmentStatus',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Days',
          value: 'days',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Class Time',
          value: 'classTime',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Outstanding',
          value: 'outstanding',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Pay Status',
          value: 'payStatus',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Student Type',
          value: 'studentType',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Last Update User',
          value: 'lastUpdateUser',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Last Update Date',
          value: 'lastUpdateDate',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Preceding Class',
          value: 'precedingClass',
          sortable: false,
          width: '10%',
        },
        {
          text: 'Remark',
          value: 'remark',
          sortable: false,
          // width: '20%',
        },
      ],
      courseListFiltered: [],
      filters: {
        classCode: [],
        startDate: [],
        endDate: [],
        classEndDate: [],
        enrollmentStatus: [],
        days: [],
        classTime: [],
        outstanding: [],
        payStatus: [],
        studentType: [],
        lastUpdateUser: [],
        lastUpdateDate: [],
        precedingClass: [],
        remark: [],
      },
    }
  },
  created() {
    this.getRegiteredCourse()
  },
  computed: {
    courseList() {
      return this.courseListFiltered.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },
    groupColumnValueList(val) {
      return this.courseListFiltered.map(d => d[val])
    },
    formatEnrollmentStatus(status) {
      switch (status) {
        case 'Stop/Trasfer':
          return 'quit-stt'
        case 'Finish':
          return 'finished-stt'
        case 'In progress':
          return 'inprogress-stt'
        default:
          break
      }
    },
    viewClassDetail(classID) {
      if (classID == null || classID == 0) return
      this.$router.push({
        name: 'class-management-detail',
        query: {
          classID: classID,
        },
      })
    },
    async getRegiteredCourse() {
      this.isLoading = true
      await student_profile.getRegistedCourse(this.userID).then(res => {
        this.courseListFiltered = res
      })
      this.isLoading = false
    },
    numberWithCommas(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  color: #427df2 !important;
}
.link:hover {
  text-decoration: underline;
  font-weight: 700;
}
.quit-stt {
  border-radius: 5px;
  padding: 5px 12px;
  text-align: center;
  background-color: #ffdbe0;
  color: #ec1c24;
}
.finished-stt {
  border-radius: 5px;
  padding: 5px 12px;
  text-align: center;
  background-color: #dbffee;
  color: #009b4a;
}
.inprogress-stt {
  border-radius: 5px;
  padding: 5px 12px;
  text-align: center;
  background-color: #fffcdc;
  color: #f6a300;
}
.select-paging {
  height: 32px;
  max-width: 100px;
}
</style>
