const dayjs = require('dayjs')
export default {
    name: 'SortByOrder',
    data() {
        return {
            // isError: false,
        }
    },
    methods: {
        getLatestInprogress(classList) {
            const getClassStatus = (item) => {
                const today = dayjs().format('YYYY/MM/DD')

                let status = ''
                if (
                    dayjs(item.actEndDate).format('YYYY/MM/DD') !=
                    dayjs('1900-01-01T00:00:00').format('YYYY/MM/DD')
                ) {
                    status = 'Quit'
                } else if (today <= dayjs(item.endDate).format('YYYY/MM/DD')) {
                    status = 'In-progress'
                } else {
                    status = 'Finished'
                }

                return status
            };

            classList.forEach(el => {
                el.FEstatus = getClassStatus(el)
            })
            // Define the order of statuses
            const statusOrder = {
                'In-progress': 1,
                Finished: 2,
                Quit: 3
            }

            // Use the sort function to sort the array based on the status order
            return classList.sort(
                (a, b) => statusOrder[a.FEstatus] - statusOrder[b.FEstatus]
            )
        },

    },
}