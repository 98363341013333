<template>
  <div class="wrap-information">
    <div class="flex-50 d-flex flex-column">
      <div class="student-info">
        <h2>Student information</h2>
        <div class="d-flex pt-1">
          <span style="flex: 30">Counselor</span>
          <a style="flex: 70">{{ infoDetail.counselorName ? infoDetail.counselorName : '-' }}</a>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Source reference</span>
          <span style="flex: 70">{{ infoDetail.sourceReference ? infoDetail.sourceReference : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Referral by</span>
          <span style="flex: 70">-</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Chanel</span>
          <span style="flex: 70">{{ infoDetail.channel ? infoDetail.channel : '-' }}</span>
        </div>
        <div class="max-content pt-1">
          <v-checkbox label="Created App account" readonly hide-details :value="infoDetail.createdAppAccount" />
          <v-checkbox label="Sent Email" readonly hide-details :value="infoDetail.sentEmail"/>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Chanel note</span>
          <span style="flex: 70">{{ infoDetail.channelNote ? infoDetail.channelNote : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Record type</span>
          <span style="flex: 70">{{ infoDetail.customerGroup ? infoDetail.customerGroup : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Date of joining</span>
          <span style="flex: 70">{{ infoDetail.dateOfJoining ? _dayjs(infoDetail.dateOfJoining).format('DD/MM/YYYY')  : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Prospect code</span>
          <span style="flex: 70">{{ infoDetail.prospectCode ? infoDetail.prospectCode : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Center</span>
          <span style="flex: 70">{{ infoDetail.location ? infoDetail.location : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Remark</span>
          <span style="flex: 70">
            {{ infoDetail.remarks ? infoDetail.remarks : '-' }}
          </span>
        </div>
      </div>
      <div class="contact-info">
        <h2>Contact information</h2>
        <div class="d-flex pt-1">
          <span style="flex: 30">Contact name</span>
          <span style="flex: 70">{{ infoDetail.contactName ? infoDetail.contactName : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Home phone</span>
          <span style="flex: 70">{{ infoDetail.homePhone ? infoDetail.homePhone : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Cell phone</span>
          <span style="flex: 70">{{ infoDetail.mobilePhone ? infoDetail.mobilePhone : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Office phone</span>
          <span style="flex: 70">{{ infoDetail.officePhone ? infoDetail.officePhone : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Email</span>
          <span style="flex: 70">{{ infoDetail.email ? infoDetail.email : '-' }}</span>
        </div>
      </div>
    </div>
    <div class="flex-50">
      <div class="guardian-info">
        <h2>Guardian information</h2>
        <div class="d-flex pt-1">
          <span style="flex: 30">Mother name</span>
          <span style="flex: 70">{{ infoDetail.motherName ? infoDetail.motherName : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Cell phone</span>
          <span style="flex: 70">{{ infoDetail.motherPhone ? infoDetail.motherPhone : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Email</span>
          <span style="flex: 70">{{ infoDetail.motherEmail ? infoDetail.motherEmail : '-' }}</span>
        </div>
        <v-checkbox class="max-content pt-1" label="Created App account" readonly hide-details :value="infoDetail.motherCreateAccount" />
        <div class="d-flex pt-1">
          <span style="flex: 30">Father name</span>
          <span style="flex: 70">{{ infoDetail.fatherName ? infoDetail.fatherName : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Cell phone</span>
          <span style="flex: 70">{{ infoDetail.fatherPhone ? infoDetail.fatherPhone : '-' }}</span>
        </div>
        <div class="d-flex pt-1">
          <span style="flex: 30">Email</span>
          <span style="flex: 70">{{ infoDetail.fatherEmail ? infoDetail.fatherEmail : '-' }}</span>
        </div>
        <v-checkbox class="max-content pt-1" label="Created App account" readonly hide-details :value="infoDetail.fatherCreateAccount"/>
      </div>
      <div class="relative-account">
        <h2>Relative Account</h2>
        <v-data-table
          :headers="headers"
          :items="studentList"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @pageCount="pageCount"
          class="elevation-0"
          fixed-header
          height="60vh"
          hide-default-header
        >
          <template v-slot:header="{ props }">
            <thead class="v-data-table-header">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  role="columnheader"
                  scope="col"
                  aria-label="No"
                  class="text-start font-weight-bold"
                  :style="'width:' + header.width + '; min-width:' + header.width + ';'"
                >
                  <span>{{ header.text }}</span>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:[`item.createdAppAccount`]="{ item }">
            <div class="checkbox-created-account">
              <v-checkbox v-model="item.createdAppAccount" readonly hide-details />
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { student_profile } from '@/api/student-profile.js'
import dayjs from 'dayjs'

export default {
  props: {
    userID: Number,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10000,
      infoDetail: {},
      headers: [
        {
          text: 'Student Code',
          value: 'studentCode',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Student Name',
          value: 'fullname',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Relative Type',
          value: 'relativeType',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Created App Account',
          value: 'createdAppAccount',
          sortable: false,
          // width: '20%',
        },
      ],
      studentList: [],
    }
  },
  created() {
    this.getStudentInformationDetail()
    this.getStudentInformationRelative()
  },
  methods: {
    async getStudentInformationDetail() {
      this.isLoading = true
      await student_profile.getStudentInfoDetail(this.userID).then(res => {
        this.infoDetail = res
        this.isLoading = false
      })
    },
    async getStudentInformationRelative() {
      this.isLoading = true
      await student_profile.getStudentInfoRelative(this.userID).then(res => {
        this.studentList = res
        this.isLoading = false
      })
    },
    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.wrap-information {
  padding: 20px 24px;
  display: flex;
  .contact-info {
    margin-top: 50px;
  }
  .relative-account {
    padding-top: 20px;
    .checkbox-created-account {
      ::v-deep .v-input--selection-controls__input {
        margin: auto;
      }
    }
  }
}
.max-content {
  width: max-content;
}
.flex-50 {
  flex: 50;
}
::v-deep .v-input--selection-controls {
  margin-top: 0;
}
</style>
