<template>
  <div class="telesale-info">
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primary"
      class="my-10"
    ></v-progress-linear>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="telesaleList"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @pageCount="pageCount"
      class="elevation-0"
      fixed-header
      height="60vh"
      hide-default-header
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              role="columnheader"
              scope="col"
              aria-label="No"
              class="text-start font-weight-bold"
              :style="'width:' + header.width + '; min-width:' + header.width + ';'"
            >
              <span>{{ header.text }}</span>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.tranDate`]="{ item }">
        <p class="text-center"> {{ _dayjs(item.tranDate).format('DD/MM/YYYY') }}</p>
      </template>
      <template v-slot:[`item.contractType`]="{ item }">
        <p class="text-center"> {{ item.contractType ? item.contractType : '-'}}</p>
      </template>
      <template v-slot:[`item.counselorNote`]="{ item }">
        <p> {{ item.counselorNote ? item.counselorNote : '-' }}</p>
      </template>
      <template v-slot:[`item.telesalesStatus`]="{ item }">
        <p class="text-center"> {{ item.telesalesStatus ? item.telesalesStatus : '-' }}</p>
      </template>
      <template v-slot:[`item.category`]="{ item }">
        <p class="text-center"> {{ item.category ? item.category : '-' }}</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { student_profile } from '@/api/student-profile.js'
import dayjs from 'dayjs'
export default {
  props: {
    userID: Number,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 10000000,
      headers: [
        {
          text: 'Tran Date',
          value: 'tranDate',
          sortable: false,
          // width: '20%',
        },
        {
          text: "Counselor's note",
          value: 'counselorNote',
          sortable: false,
          // width: '20%',
        },
        {
          text: "Customer's feedback",
          value: 'customerNote',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Contract Type',
          value: 'contractType',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
          // width: '20%',
        },
        {
          text: 'Status',
          value: 'telesalesStatus',
          sortable: false,
          // width: '20%',
        },
      ],
      telesaleList: [],
    }
  },
  created(){
    this.getTelesaleInfo()
  },
  methods:{
    async getTelesaleInfo(){
      this.isLoading = true
      this.telesaleList = await student_profile.getTelesaleInfo(this.userID)
      this.isLoading = false
    },
    _dayjs: dayjs,
  }
}
</script>

<style lang="scss" scoped>
.telesale-info {
  padding: 20px 24px;
  .select-paging {
    height: 32px;
    max-width: 100px;
  }
}
</style>
