<template>
  <div class="pt-5 pl-10 pr-10">
    <h2 class="mb-5">Nhận xét hàng tháng</h2>
    <Autocomplete
      :style="{ width: '180px' }"
      @setFilter="setFilter"
      :styleBorder="''"
      :keyFilter="'none'"
      :labelSelect="'class'"
      :selectedInit="listClassSelect"
      :listItem="listClassCode"
    />
    <div ref="listComment">
      <v-progress-linear indeterminate v-if="loading" color="primary"></v-progress-linear>
      <div v-else>
        <CommentItem
          v-for="comment in dataComments"
          @setLog="setLog"
          :key="comment.commentID"
          :comment="comment"
          :isGuardianFeedback="false"
        />
      </div>
    </div>
    <h4 v-if="dataComments.length < 1" class="nodata">No data in here</h4>
    <DigCommentDetailStudent
      @getListComment="getListComment"
      :comment="commentSelect"
      :dialog="dialog"
      @setLog="setLog"
    />
  </div>
</template>

<script>
import { monthly_comment } from '@/api/monthly.js'
import { class_managerment } from '@/api/class-management'
import CommentItem from '@/components/MonthCommentStudent/CommentItem.vue'
import DigCommentDetailStudent from '@/components/MonthCommentStudent/DigCommentDetailStudent.vue'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import dayjs from 'dayjs'
import SortByOrder from '@/mixins/sortClassByOrder.js'


export default {
  components: {
    CommentItem,
    DigCommentDetailStudent,
    Autocomplete,
  },
  mixins: [SortByOrder],
  setup() {
    return {
      dayjs: dayjs,
    }
  },
  data() {
    return {
      dialog: false,
      userId: this.$route.params.userId,
      commentSelect: {},
      dataComments: [],
      listClass: [],
      listClassCode: [],
      loading: false,
      listClassSelect: [],
    }
  },
  created() {
    this.getListClassStuding()
  },
  methods: {
    setFilter(objectFilterChange) {
      this.listClassSelect = objectFilterChange.filter
    },
    async getListComment() {
      if (this.userId && this.userId !== 0) {
        let listCLassToString = this.listClass
          .filter(item => this.listClassSelect.includes(item.classCode))
          .map(item => item.classID)
          .join(',')
        if (this.listClassSelect.length < 1) {
          listCLassToString = this.listClass.map(item => item.classID).join(',')
        }

        this.loading = true
        await monthly_comment
          .getCommentByStudent(this.userId, listCLassToString)
          .then(res => {
            this.dataComments = res.reverse()
            this.loading = false
          })
          .catch(() => {
            this.dataComments = []
            this.loading = false
          })
      }
    },
    async getListClassStuding() {
      await class_managerment
        .getClassRe(this.userId)
        .then(res => {
          const targetResult = this.getLatestInprogress(res)
          this.listClass = targetResult
          this.listClassSelect.push(targetResult[0].classCode)
          this.listClassCode = targetResult.map(el => el.classCode)
        })

        .catch(err => {
          this.listClass = []
          this.listClassSelect = []
          this.listClassCode = []
          console.log(err)
        })
    },
    setLog(inf) {
      if (inf) {
        this.dialog = inf.dialog
        this.commentSelect = inf.comment
      }
    },
  },
  watch: {
    listClassSelect: function () {
      this.getListComment()
    },
  },
}
</script>

<style lang="scss" scoped>
.select-class {
  width: 200px;
}
.nodata {
  text-align: center;
}
</style>
